<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <span
            >部门：
            <DeptCascader
              :placeholder="'请选择部门'"
              :deptList="deptList"
              v-model="parameter.deptId"
            />
          </span>
          <span
            >员工名称：
            <el-autocomplete
              class="ipt_width project_width"
              v-model="parameter.userName"
              :fetch-suggestions="userNameQuerySearch"
              placeholder="请输入或选择员工名称"
              @select="onProkectNameSelect"
              :popper-append-to-body="false"
              clearable
            >
            </el-autocomplete
          ></span>
          <span
            >项目名称：
            <el-select
              v-model="parameter.projectId"
              clearable
              filterable
              placeholder="请选择项目名称"
              class="ipt_width project_width"
            >
              <el-option
                v-for="item in projectList"
                :key="item.id"
                :label="item.projectName"
                :value="item.id"
              >
              </el-option> </el-select
          ></span>
          <span
            >工时日期：
            <el-date-picker
              class="date_width"
              v-model="createdDate"
              :pickerOptions="pickerOptions"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker
          ></span>

          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :summary-method="getSummaries"
            show-summary
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
            @cell-click="handleCellClick"
          >
            <el-table-column align="center" type="index" label="序号" width="70"></el-table-column>
            <el-table-column align="center" prop="projectName" label="项目名称" min-width="240">
            </el-table-column>
            <el-table-column
              align="center"
              prop="userName"
              label="填报人"
              min-width="90"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="departmentName"
              label="所属部门"
              min-width="110"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="projectOverall"
              label="项目进度"
              min-width="90"
            ></el-table-column>
            <!-- <el-table-column
              align="center"
              prop="workContent"
              label="工作内容"
              min-width="240"
            ></el-table-column>
            <el-table-column align="center" prop="checkStatus" label="审批状态" width="150">
              <template slot-scope="scope">
                {{ scope.row.checkStatus.toString() | dict(dictData.manhourCheackStatus) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="checkEmployee" label="审批者" width="120">
            </el-table-column>
            <el-table-column
              align="center"
              prop="checkOpinion"
              label="审批意见"
              min-width="180"
            ></el-table-column>

            <el-table-column label="有无重要活动" align="center" prop="outgoingType" width="110">
              <template slot-scope="scope">
                <div v-if="scope.row.outgoingType == 'HAVE'">
                  <el-button type="primary" size="mini" plain @click="getOutReportData(scope.row)"
                    >查看报告</el-button
                  >
                </div>
                <div v-else>无</div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="isTraveling" label="是否外勤" width="110">
              <template slot-scope="scope">
                <span v-if="scope.row.isTraveling == 'Y'"
                  ><el-button type="primary" size="mini" plain @click="showOutWorkDetail(scope.row)"
                    >查看报告</el-button
                  ></span
                >
                <span v-else>否</span>
              </template>
            </el-table-column> -->
            <el-table-column
              align="center"
              prop="hour"
              label="填报工时"
              min-width="90"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="auditHour"
              label="审核工时"
              min-width="90"
            ></el-table-column>
            <el-table-column
              align="center"
              v-show="isSpecial"
              prop="isTraveling"
              label="操作"
              width="80"
            >
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="hourDetail(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="工作内容" :visible.sync="workContentDialogVisible" width="40%">
      <div class="dialogContentHeight">{{ workContentDialog }}</div>
    </el-dialog>

    <OutReportDetail
      v-if="isOutReportDialogShow"
      :isOutReportDialogShow.sync="isOutReportDialogShow"
      :outReportData="manhourOutgoing"
    >
    </OutReportDetail>

    <OutWorkDetail
      v-if="isOutWorkDetailShow"
      :isOutWorkDialogShow.sync="isOutWorkDetailShow"
      :outWorkInfo="manhourTraveling"
    />
  </div>
</template>
<script>
import { encrypt, decrypt } from '@/util/jsencrypt'
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    OutReportDetail: () => import('@/components/hour/out-report-detail.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
    OutWorkDetail: () => import('@/components/hour/out-work-detail.vue'),
  },
  data() {
    const end = new Date()
    const start = new Date()
    start.setDate(1)
    // 设置日期为上个月的1号
    start.setMonth(start.getMonth() - 1)
    // 设置日期为上个月的最后一天
    end.setMonth(end.getMonth())
    end.setDate(0)
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        userName: '',
        deptId: '',
        projectId: '',
      },
      pageSize: 0,
      tableData: [],
      loading: false,
      createdDate: [start, end], // 默认值设置为上个月的1号到上个月的最后一天
      deptList: [],
      dictData: {
        manhourType: [],
        manhourCheackStatus: [],
      },
      workContentDialogVisible: false,
      workContentDialog: '',
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },

      manhourOutgoing: null, //外出活动报告数据源
      isOutReportDialogShow: false,
      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'type',
      },
      manhourTraveling: {}, //外勤数据源
      isOutWorkDetailShow: false,
      userNameList: [],
      projectList: [],
      hourInfo: {
        auditHourAll: '',
        hourAll: '',
      },
      isSpecial: false,
    }
  },
  provide() {
    return {
      re: this.re,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
          this.parameter.beginDate = ''
          this.parameter.endDate = ''
        }
      },
    },
    'parameter.deptId': {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.getUserNameList(val)
        } else {
          this.getUserNameList()
        }
      },
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getDictData()
    this.getData()
    this.getProject()
    this.get_DeptList()
  },
  updated() {
    this.$nextTick(() => {
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.doLayout()
      }
    })
  },
  methods: {
    hourDetail(row) {
      // 需要带上相应的工时类型
      this.$router.push({
        path: '/hour/byUser/List',
        query: {
          manhourType: encrypt('0'),
          projectId: encrypt(row.projectId),
          userName: row.userName,
          createdDate: JSON.stringify(this.createdDate),
        },
      })
    },
    getUserNameList(deptId = '') {
      this.$api.user
        .listStaff({ deptId, pageSize: 9999, pageNow: 1 })
        .then(res => {
          this.userNameList = res.data.records
        })
        .catch(err => {
          console.log(err)
        })
    },
    userNameQuerySearch(queryString, cb) {
      let userNameList = this.userNameList.map(item => {
        return { value: item.userName }
      })
      let results = queryString ? this.userNameCreateFilter(queryString) : userNameList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    userNameCreateFilter(queryString) {
      let results = this.userNameList.map(item => {
        return { value: item.userName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.userName = e.value
      }
      this.$forceUpdate()
    },
    getDictData() {
      this.$api.dict
        .listSysDictData('MANHOUR_TYPE', true)
        .then(res => {
          this.dictData.manhourType = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.dict
        .listSysDictData('MAHHOURS_CHECK_STATUS', true)
        .then(res => {
          this.dictData.manhourCheackStatus = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        // 如果是特殊处理的列
        if (column.property === 'hour') {
          sums[index] = this.hourInfo.hourAll
          return
        }
        if (column.property === 'auditHour') {
          sums[index] = this.hourInfo.auditHourAll
          return
        }
      })

      return sums
    },
    getData() {
      this.loading = true
      if (this.createdDate && this.createdDate.length !== 0) {
        this.parameter.beginDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      }
      // 查询路由参数是否需要解密
      if (this.$route.query.userName) {
        this.parameter.userName = this.$route.query.userName
      }
      if (this.$route.query.createdDate) {
        this.createdDate = JSON.parse(this.$route.query.createdDate)
        this.parameter.beginDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      }
      this.$api.hour
        .getProductionMahhoursDetails(this.parameter)
        .then(res => {
          this.isSpecial = false
          if (res.data == null) {
            this.tableData = []
          } else {
            this.tableData = res.data?.manhourPBMPageIPage?.records
            this.hourInfo.auditHourAll = res.data?.auditHourAll
            this.hourInfo.hourAll = res.data?.hourAll
            this.isSpecial = res.data.isSpecial
            this.parameter.total = res.data?.manhourPBMPageIPage?.total
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 清理路由参数的方法
    clearRouteParams() {
      // 使用 replace 方法替换当前路由，并确保没有查询参数
      this.$router.replace({ path: '/hour/projectHour', query: {} }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
      this.clearRouteParams()
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    handleReset() {
      this.createdDate = []
      this.parameter = {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        deptId: '',
        projectId: '',
        userName: '',
      }
      this.clearRouteParams()
      this.getData()
    },
    get_DeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
          this.$store.commit({
            type: 'business/SET_DEPT_LIST',
            selectDeptList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    handlePageSize() {
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    getProject() {
      this.$api.project
        .getProjectInfo()
        .then(res => {
          if (!res.data) {
            this.projectList = []
          } else {
            this.projectList = res.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 打开预览工作内容
    handleCellClick(row, column) {
      if (column.label == '工作内容') {
        this.workContentDialog = row.workContent
        this.workContentDialogVisible = true
      }
    },

    getOutReportData(row) {
      if (row.outgoingType == 'HAVE') {
        this.manhourOutgoing = null
        // 需要调接口获取外出活动内容
        this.$api.hour
          .selectManhourOutgoingDetailByManhourId(row.id)
          .then(res => {
            if (res.data) {
              this.manhourOutgoing = res.data
              this.isOutReportDialogShow = true
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    showOutWorkDetail(row) {
      this.manhourTraveling = {}
      // 需要调接口获取外勤内容
      this.$api.hour
        .selectDetailByManhourId(row.id)
        .then(res => {
          if (res.data) {
            this.manhourTraveling = res.data
            this.isOutWorkDetailShow = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.dialogContentHeight {
  height: 250px;
  overflow: auto;
  white-space: pre-wrap;
  padding: 0px 20px;
}
</style>
